import Cookies from 'universal-cookie';
export const host = 'https://blog.api.newwave4.org';
//https://blog.api.newwave4.org
//http://localhost:8080
// From files https://newwave4.org/


export const getParams = (methodType, useAuth) => {
  const params = {
    method: methodType,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Request-Method': methodType,
      'Access-Control-Request-Headers': 'Content-Type',
      'Content-Type': 'application/json',
    },
  };
  const cookies = new Cookies();
  const token = cookies.get("token");
  if (!!token) {
    if (!!useAuth) {
      params.headers.Authorization = token;
    }
  } else {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('rights');
    localStorage.removeItem('userId');
  }
  return params;
};