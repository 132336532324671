// import React from "react";
import React, { useEffect } from "react";
import {Col} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import i18n from "../../../i18n";

function SchoolPage() {

  useEffect(() => {
    window.location.href = "https://newwaveschool.org/";
  }, []);

  return (
    <div>
      <h2></h2>
    </div>
  );
}
export default withTranslation()(SchoolPage);
