import React from "react";
import {Alert, Button, Col, Form, FormControl, InputGroup, TabPane} from "react-bootstrap";
import i18n from "../../../i18n";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators} from "../../../store/main/Main-actions";
import {history} from "../../App";
import qr_code from "../donations/QR_Code.png";

class DonationsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      email: '',
      amount: null,
      cardNumber: '',
      expMonth: null,
      expYear: null,
      cvc: null,
      isDonating: false
    }
  }
  render () {
    return (
        <Col className="text-center" xs md={{ span: 8, offset: 2 }}>
          <h2 className="p-3 text-secondary">{i18n.t("donation.title")}</h2>
          <p className="text-center">{i18n.t("donation.sub-title-1")}<br/>
            {i18n.t("donation.sub-title-2")}</p>
          <Form onSubmit={async(e) => {
            e.preventDefault();
              this.setState({isDonating: true});
              await this.props.donate(this.state.fullName, this.state.email, this.state.amount,
                this.state.cardNumber.replace(/\s+/g, ""), this.state.expMonth, this.state.expYear, this.state.cvc);
            if (!this.props.errorMessage) {
                history.push("/result");
                await this.setState({ isDonating: false });
            }
          }}>
            <InputGroup className="mb-3">
              <Form.Check
                  checked={true}
                  name="donate-by"
                  inline
                  label={i18n.t("donation.donate-by") + "Credit Card"}
                  type="radio"
                  id="donate-credit-card"
              />
              {/* <Form.Check
                  checked={true}
                  name="donate-by"
                  inline
                  disabled
                  label={i18n.t("donation.donate-by") + "PayPal"}
                  type="radio"
                  id="donate-pay-pal"
              /> */}
              {/* <Form.Check
                  name="donate-by"
                  inline
                  disabled
                  label={i18n.t("donation.donate-by") + i18n.t("donation.check")}
                  type="radio"
                  id="donate-check"
              /> */}
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="contact-us-name">&nbsp;&#9829;</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl required
                           value={this.state.fullName}
                           onChange={(e) => this.setState({fullName: e.target.value})}
                           placeholder={i18n.t("donation.name")}
                           aria-label="Name"
                           aria-describedby="contact-us-name"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="contact-us-email">@</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl required
                           value={this.state.email}
                           onChange={(e) => this.setState({email: e.target.value})}
                           placeholder={i18n.t("donation.email")}
                           aria-label="Email"
                           aria-describedby="contact-us-email"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="contact-us-amount">&nbsp;$&nbsp;</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl required type="number"
                           value={this.state.amount}
                           onChange={(e) => this.setState({amount: e.target.value})}
                           placeholder={i18n.t("donation.amount")}
                           aria-label="Amount"
                           aria-describedby="contact-us-amount"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <FormControl required
                           value={this.state.cardNumber}
                           onChange={(e) => {
                             const normalizedVal = e.target.value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
                             let newVal = "";
                             for (let i = 0; i < Math.ceil(normalizedVal.length / 4); i++){
                               newVal += normalizedVal.slice(i * 4, (i + 1) * 4) +
                                   ((normalizedVal.length <= (i + 1) * 4) ? "" : " ");
                             }
                             e.target.value = newVal;
                             this.setState({cardNumber: newVal})
                           }}
                           placeholder={i18n.t("donation.card-number")}
                           aria-label="Card number"
                           aria-describedby="card-number"/>
            </InputGroup>
            <InputGroup className="mb-3">
              <FormControl required type="number"
                           value={this.state.expMonth}
                           onChange={(e) => this.setState({expMonth: e.target.value})}
                           placeholder={i18n.t("donation.valid-till-month")}
                           aria-label="Valid month"
                           aria-describedby="valid-month"/>
              <FormControl required type="number"
                           value={this.state.expYear}
                           onChange={(e) => this.setState({expYear: e.target.value})}
                           placeholder={i18n.t("donation.valid-till-year")}
                           aria-label="Valid year"
                           aria-describedby="valid-year"/>
              <FormControl required type="number"
                           value={this.state.cvc}
                           onChange={(e) => this.setState({cvc: e.target.value})}
                           placeholder={i18n.t("donation.cvc")}
                           aria-label="CVC code"
                           aria-describedby="cvc-code"/>
            </InputGroup>
            {!!this.props.errorMessage && (<Alert variant="danger" className="mt-3">{i18n.t(this.props.errorMessage)}</Alert>)}
            <Form.Group className="pt-3 d-flex justify-content-between">
              <h2>{!!this.state.amount &&
              (i18n.t("donation.full-amount") + ' ' + Number.parseFloat((parseFloat(this.state.amount) + 0.3) / (1 - 0.029)).toFixed(2) + '$')}</h2>
              <Button variant="secondary" type="submit" disabled={this.state.isDonating}>
                  {this.state.isDonating ? i18n.t("donation.btn-donating") : i18n.t("donation.btn-donate")}</Button>
            </Form.Group>
          </Form>

        
          <h2 className="p-3 text-secondary">{i18n.t("donation.pay-pal")}</h2>
          <QR_Code /><br/>
          <br/>
          <form action="https://www.paypal.com/donate" method="post" target="_top">
          <input type="hidden" name="hosted_button_id" value="ZUU5WYF4GKKE4" />
          <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
          <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
          </form>


          <h2 className="p-3 text-secondary">{i18n.t("donation.title-bank-account")}</h2>
          <p className="text-left"><strong>{i18n.t("donation.bank-unfcu")}</strong><br/>
          {i18n.t("donation.bank-address-unfcu")}<br/>
          {i18n.t("donation.new-wave-corp-unfcu")}<br/>
          {i18n.t("donation.route-num-unfcu")}<br/>
          {i18n.t("donation.account-num-unfcu")}<br/>
          <strong>{i18n.t("donation.memo")}</strong><br/>
          </p>
          <p className="text-left"><strong>{i18n.t("donation.bank-td-write")}</strong> {i18n.t("donation.bank-td-write-to")}<br/>
          <strong>{i18n.t("donation.bank-td-aba")}</strong>{i18n.t("donation.bank-td-aba-num")}<br/>
          <strong>{i18n.t("donation.bank-td-credit-to")}</strong><br/>
          {i18n.t("donation.account-num-td")}<br/>
          {i18n.t("donation.new-wave-corp-td")}<br/>
          {i18n.t("donation.bank-address-td")}<br/>
          </p>
        </Col>
    );
  }
}

function QR_Code() {
  return <img src={qr_code} alt="qr_code" />;
}

export default connect(
  state => state.mainReducer,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(withTranslation()(DonationsPage));