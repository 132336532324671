export const requestType = "REQUEST_STARTED";
export const requestPassedType = "REQUEST_SUCCESSFUL";
export const requestFailedType = "REQUEST_FAILED";
export const clearErrorsType = "CLEAR_ERRORS";
export const loginPassedType = "LOGIN_SUCCESSFUL";
export const logoutType = "LOGOUT";
export const addEditItemPassedType = "ADD_EDIT_ITEM_SUCCESSFUL";
export const toggleAddEditModalType = "TOGGLE_ADD_EDIT_MODAL";
export const receivedCategoriesType = "RECEIVED_CATEGORIES";
export const receivedProjectsType = "RECEIVED_PROJECTS";
export const receivedArticlesType = "RECEIVED_ARTICLES";
export const receivedItemsType = "RECEIVED_ITEMS";
export const receivedFilterDates = "RECEIVED_FILTER_DATES";
export const clearFilterDates = "CLEAR_FILTER_DATES";
export const receivedAuthor = "RECEIVED_AUTHOR";
export const itemDeletedType = "ITEM_DELETED";
export const changeActiveItemsType = "CHANGE_ACTIVE_ITEMS";
